<!--消息管理-短信发送-->
<template>
  <div class="public_participation_page">
    <el-form inline size="mini">
      <el-form-item>
        <el-date-picker
          placeholder="发送时间"
          type="date"
          v-model="filter.sendDate"
          @change="searchData"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="false"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleShowBatchSendDialog"
        >新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      size="mini"
      border
      style="width: 100%;"
      @selection-change="selsChange"
      :max-height="clientHeight"
      :data="dataObj.list"
      :loading="loading"
    >
      <el-table-column
        type="index"
        label="序号"
        align="center"
        width="55"
      />
      <el-table-column
        prop="SendDate"
        label="发送时间"
        align="center"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="SenderName"
        label="发送人"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="LocaleName"
        label="监测点"
        align="center"
        width="280"
      ></el-table-column>
      <el-table-column
        prop="Content"
        label="短信内容"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="Result"
        label="发送结果"
        align="center"
        width="200"
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="120"
        fixed="right"
        header-align="center"
      >
        <template slot-scope="scope">
          <i
            title="发送消息"
            @click="handleShowSendMsgDialog(scope.row)"
            class="opt el-icon-message"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="filter.page"
        :page-sizes="[20, 40, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataObj.total"
      ></el-pagination>
    </div>

    <el-dialog
      width="25%"
      :close-on-click-modal="false"
      :visible.sync="dlg.visibleOneSend"
      @close="handleDialogClose"
    >
      <el-form
        :model="formData"
        label-width="124px"
        ref="formSendOne"
        :rules="formSendOneRule"
        size="mini"
      >
        <el-row>
          <el-form-item
            prop="LocaleName"
            label="监测点名称"
          >
            <el-input
              disabled
              v-model.trim="formData.LocaleName"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item
            prop="Content"
            label="消息内容"
          >
            <el-input
              type="textarea"
              v-model.trim="formData.Content"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="dlg.visibleOneSend = false"
        >取消
        </el-button>
        <el-button type="primary" size="mini" @click.native="handleSendOne" :loading="loading"
        >重新发送
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      width="25%"
      :close-on-click-modal="false"
      :visible.sync="dlg.visibleBatchSend"
      @close="handleDialogClose"
    >
      <el-form
        :model="formData"
        label-width="124px"
        ref="formSendBatch"
        :rules="formSendBatchRule"
        size="mini"
      >
        <el-row>
          <el-form-item
            prop="Owner"
            label="请选择发送点位"
          >
            <el-cascader
              placeholder="所属单位"
              v-model.trim="formData.Owner"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable
            />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item
            prop="Content"
            label="消息内容"
          >
            <el-input
              type="textarea"
              v-model.trim="formData.Content"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="dlg.visibleBatchSend = false"
        >取消
        </el-button>
        <el-button type="primary" size="mini" @click.native="handleBatchSend" :loading="loading"
        >提交
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import {dateFormater, getUserInfo, ifNull, join, maintenanceTypeOptions, referToMap} from '@/util/index'
import {mapState} from 'vuex'

export default {
  name: 'MessageManagerment',
  data() {
    return {
      filter: {
        StartAt: 0,
        Size: 20,
        page: 1,
        size: 20,
        sendDate: ''
      },
      loading: false,
      dlg: {
        visibleOneSend: false,
        visibleBatchSend: false,
        submitted: false
      },
      // 列表数据
      dataObj: {
        list: [],
        total: 0
      },
      selRows: [],
      formData: {
        Id: '',
        Owner: '',
        LocaleName: '',
        Content: ''
      },
      formSendOneRule: {
        Content: [{required: true, message: '内容不可为空', trigger: 'blur'}]
      },
      formSendBatchRule: {
        Owner: [{required: true, message: '单位不可为空', trigger: 'blur'}],
        Content: [{required: true, message: '内容不可为空', trigger: 'blur'}]
      }
    }
  },
  mounted() {
    this.init()

  },
  computed: {
    ...mapState({
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['customerTree','clientHeight']),
  },
  methods: {
    init() {
      this.loading = true
      if (this.$route.query.type) {
        this.filter.msg_type = this.$route.query.type
      }
      this.netGetMessageList() // 获取消息列表

    },
    getLocaleNameFromContent(content) {
      const strs = content.match(/\【(.+?)\】/g)
      if (Array.isArray(strs) && strs) {
        return strs[0]
      }
      return ''
    },
    handleBatchSend() {
      this.$refs.formSendBatch.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示')
            .then(() => {
              this.dlg.submitted = true
              this.loading = true
              const para = {
                Content: this.formData.Content,
                Owner: join(this.formData.Owner)
              }
              this.$post('admin/batchSendMessage', para)
                .then((res) => {
                  this.$message.success(res)
                  this.dlg.visibleBatchSend = false
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            })
        }
      })
    },
    handleSendOne() {
      this.$refs.formSendOne.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示')
            .then(() => {
              this.dlg.submitted = true
              this.loading = true
              let param = new FormData()
              param.append("ids",this.formData.TagId)
              if (this.formData.TagId.indexOf("wash_")>-1) {
                param.append("sendType","filterWash")
              } else {
                param.append("sendType","alarm")
              }
              this.$post('admin/sendOneMessage', param)
                .then((res) => {
                  this.$message.success(res)
                  this.dlg.visibleOneSend = false
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            })
        }
      })
    },
    handleRefresh(clearFilter = false) {
      if (clearFilter) {
        this.filter.page = 1
        this.filter.StartAt = 0
      }
      this.netGetMessageList()
    },
    /**
     * @param submitted 如果是提交后关闭，则刷新
     */
    handleDialogClose() {
      if (this.dlg.submitted) {
        this.changeState = false
        this.handleRefresh(false)
      }
      this.formData = {
        Id: '',
        Owner: '',
        LocaleName: '',
        Content: ''
      }
    },
    handleShowBatchSendDialog() {
      this.dlg.visibleBatchSend = true
      this.dlg.submitted = false
    },
    handleShowSendMsgDialog(row) {
      this.formData = {
        LocaleName: this.getLocaleNameFromContent(row.Content),
        ...row
      }
      this.dlg.visibleOneSend = true
      this.dlg.submitted = false
    },
    clearTableSelections() {
      this.$refs.table.clearSelection()
    },
    /**
     * @description 表格列选中事件
     * @param sels
     */
    selsChange(sels) {
      this.selRows = sels
    },
    /**
     * @description 获取消息列表
     */
    netGetMessageList() {
      this.loading = true
      const filter = {}
      filter.StartAt = this.filter.size * (this.filter.page - 1)
      filter.Size = this.filter.size
      filter.Param = {}
      if (this.filter.msg_type) {
        filter.Param['msg_type'] = this.filter.msg_type
      }
      if (this.filter.sendDate) {
        filter.Param['send_date'] = {S: 0, V: `'${this.filter.sendDate}'`}
      }
      this.$post('admin/listMessage', filter)
        .then(res => {
          this.dataObj.list = res.content
          this.dataObj.total = res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },
    /**
     * @description 查询
     */
    searchData() {
      this.filter.page = 1
      this.netGetMessageList()
    },

    /**
     * @description 每页条数
     */
    handleSizeChange(pageSize) {
      this.filter.page = 1
      this.netGetMessageList()
    },

    /**
     * @description 页码
     */
    handleCurrentChange(page) {
      this.filter.page = page
      this.netGetMessageList()
    }
  }
}
</script>
<style lang="scss" scoped>
.public_participation_page {
  .page_box {
    width: auto;
    padding-top: 8px;
    display: flex;
    justify-content: flex-start;

    > div {
      flex: 1;
    }
  }

  .form_item_desc {
    .el-form-item__content {
      padding: 10px 0;
    }
  }
}
</style>
